import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, TextField } from '@mui/material';
/**
 * Site OneOffPayment component
 */
export const AmountCaptureInputs = ({ onChange = () => {}, amount }) => {
  const paymentOptionAmounts = ["5.00", "10.00", "50.00", "100.00"];

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const setInputValue = (e) => {
    onChange(e.currentTarget.value);
  };

  const [paymentMode, setPaymentMode] = useState("QUICK_PAY");

  const switchPaymentMode = (paymentMode) => {
    switch (paymentMode) {
      case "QUICK_PAY":
      case "CUSTOM_AMOUNT":
        onChange(0);
        break;
    }

    setPaymentMode(paymentMode);
  };

  return (
    <section className="aquire-amount">
      <div className="aquire-amount-input-group">
        <div
          className={
            "quick-pay-buttons " +
            (paymentMode == "QUICK_PAY" ? "visible" : "hidden")
          }
        >
          <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
            {paymentOptionAmounts.map((val, id) => (
              <Button
                key={id.toString()}
                className={
                  "quick-pay-buttons-button button-1 " +
                  (amount == val ? "selected" : "")
                }
                variant={amount == val ? "contained" : "outlined"}
                onClick={() => onChange(val)}
              >
                £{val}
              </Button>
            ))}
            <Button
              onClick={() => switchPaymentMode("CUSTOM_AMOUNT")}
              className="quick-pay-buttons-button-other button-1"
            >
              Other
            </Button>
          </ButtonGroup>
        </div>
        <div
          className={
            "field other-amount-input " +
            (paymentMode == "CUSTOM_AMOUNT" ? "visible" : "hidden")
          }
        >
          <TextField
            fullWidth
            required
            id="outlined-required"
            label="Donation Amount"
            defaultValue={0}
            type={'number'}
            onKeyPress={preventMinus}
            onChange={setInputValue}
          />
        </div>
      </div>
    </section>
  );
};
