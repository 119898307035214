import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";

import { client, repositoryName } from "./prismic";
import { HomePage } from "./pages/HomePage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { EventsPage } from "./pages/EventsPage";
import { DonationsPage } from "./pages/DonationsPage";
import { NotFound } from "./pages/NotFound";
import { Page } from "./pages/Page";
import { Preview } from "./pages/Preview";
import { KioskPaymentsPage } from "./pages/KioskPaymentsPage";
import { KioskRegisterPage } from "./pages/KioskRegisterPage";
import { ContactPage } from "./pages/ContactPage";
import { ContactDonePage } from "./pages/ContactDonePage";
import { RegisterDetailsPage } from "./pages/RegisterDetailsPage";

export const App = () => {
  return (
    <PrismicProvider
      client={client}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/donations" element={<DonationsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/contact/done" element={<ContactDonePage />} />
          <Route path="/kiosk-payments" element={<KioskPaymentsPage />} />
          <Route path="/kiosk-register" element={<KioskRegisterPage />} />
          <Route path="/register" element={<RegisterDetailsPage />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/:uid" element={<Page />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
  );
};
