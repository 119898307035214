import React, { useState } from "react";
import { PrismicRichText } from "@prismicio/react";

/**
 * Team members slice
 */
export const TeamMembers = ({ slice, id }) => {
  const [expanded, setToggle] = useState(false);

  return (
    <li key={id.toString()} className="meet-the-team-list-item row">
      <div>
        <div className="col-3">
          <h3 className="full-name">{slice.fullname[0].text}</h3>
          <h4 className="role">{slice.role[0].text}</h4>
          <img src={slice.facepic.url} />
        </div>
        <div className="col-1"></div>
        <div className="col-8">
          <p className={"short-bio " + (expanded == true ? "hidden" : "")}>
            <PrismicRichText field={slice.shortbio} />
            <span onClick={() => setToggle(true)}>Read more...</span>
          </p>
          <p
            className={"full-bio " + (expanded == true ? "visible" : "hidden")}
          >
            <PrismicRichText field={slice.fullbio} />
            <span onClick={() => setToggle(false)}>Read Less</span>
          </p>
        </div>
      </div>
    </li>
  );
};
