import { Layout } from "../components/Layout";
import { useSinglePrismicDocument } from "@prismicio/react";

import { OneOffPayment } from "../components/OneOffPayment";
import {
    Unstable_Grid2 as Grid,
    Divider,
    Chip
  } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

/**
 * Website homepage component
 */
export const KioskPaymentsPage = () => {

    const [menu, menuState] = useSinglePrismicDocument("menu");

    // Return the page if a document was retrieved from Prismic
    if (menu) {
    return (
        <Layout wrapperClass="page kiosk-page" hideMenu={true} menuDoc={menu}>
        <section className="content-section text-section-1col">
            <h1>Make a donation</h1>
            <Grid container spacing={2}>
                <Grid xs={12}>
                <Alert severity="info">
                    <AlertTitle>It's Secure</AlertTitle>
                    <section>We do not store your card number, or charge any additional fees to your account. Payment pages are loaded over HTTPS, which is a secure channel between you and our servers. <a target="_blank" href="https://www.getsafeonline.org/shopping-banking/online-payments/">Find out more...</a></section>
                </Alert>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid xs={12}>
                <Divider>
                    <Chip label="Donate online now" />
                </Divider>
                </Grid>
                <br />
                <Grid xs={12}>
                <OneOffPayment />
                </Grid>
            </Grid>
            <br />
        </section>
        </Layout>
        );
    } else {
        return null;
    }

};
