import { useEffect } from "react";
import {
  SliceZone,
  useSinglePrismicDocument,
  PrismicRichText,
  PrismicText,
} from "@prismicio/react";

import { Events } from "../slices/Events";
import { Layout } from "../components/Layout";
import { GlobalBanner } from "../components/GlobalBanner";
import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const ContactPage = () => {
  const [contact, contactPageState] = useSinglePrismicDocument("contact_page");
  const [home, eventsPageState] = useSinglePrismicDocument("events_page");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound =
    eventsPageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (eventsPageState.state === "failed") {
      console.warn(
        "Teampage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [eventsPageState.state]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
                region: "eu1",
                portalId: "143151745",
                formId: "496e0c92-5944-4177-8387-e721d57a7de7",
                target: '#hubspotForm'
            })
        }
    });
}, []);

  // Return the page if a document was retrieved from Prismic
  if (home && menu) {
    return (
      <Layout wrapperClass="page contacts-page" menuDoc={menu}>
        <GlobalBanner />
        <section className="content-section text-section-1col">
          <h1>
            Contact Us
          </h1>
          <br />
          <div id="hubspotForm"></div>
          <article>
            <PrismicRichText field={home.data.eventsdescription} />
          </article>

          <ul className="events-list">
            {home.data.body[0].items.map((event, id) => (
              <Events slice={event} id={id} />
            ))}
          </ul>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
