import React, { useState } from "react";
import { Event } from "../components/Event";

/**
 * Team members slice
 */
export const Events = ({ slice, id }) => {
  const [expanded, setToggle] = useState(false);

  return (
    <li key={id.toString()} className="events-list-item row">
      <Event
        title={slice.eventtitle[0].text}
        startDate={slice.eventstartdate}
        endDate={slice.eventenddate}
        startFinishTime={slice.eventstartfinishtime[0].text}
        address={slice.eventaddress[0].text}
        shortDescription={slice.eventshortdescription}
        longDescription={slice.eventlongdescription}
        image={slice.eventimage.url}
      />
    </li>
  );
};
