import { useEffect } from "react";
import { Layout } from "../components/Layout";
import { useSinglePrismicDocument } from "@prismicio/react";
import { OneOffPayment } from "../components/OneOffPayment";
import {
    Unstable_Grid2 as Grid,
    Divider,
    Chip,
    Button
  } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

/**
 * Website homepage component
 */
export const KioskRegisterPage = () => {

    const [menu, menuState] = useSinglePrismicDocument("menu");


    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
    
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                  region: "eu1",
                  portalId: "143151745",
                  formId: "b6e8c7f6-c73e-4f06-abb8-5eb1fbc7c08c",
                  target: '#registerForm'
                });
            }
        });
    }, []);

    const reload = () => {
        window.location.reload()
    }
    

    // Return the page if a document was retrieved from Prismic
    if (menu) {
    return (
        <Layout wrapperClass="page kiosk-page" hideMenu={true} menuDoc={menu}>
        <section className="content-section text-section-1col">
            <h1>Register your details</h1>
            <p>We are refreshing our contacts database. So that we can keep in-touch with you with regards to upcoming events, please do register your details</p>
            <div id="registerForm"></div>
            <Button
                size="large"
                fullWidth
                variant="contained"
                onClick={ reload }
                className="1"
            >
            Restart
            </Button>
        </section>
        </Layout>
        );
    } else {
        return null;
    }

};
