import { useEffect } from "react";
import {
  SliceZone,
  useSinglePrismicDocument,
  PrismicRichText,
  PrismicText,
} from "@prismicio/react";

import { Events } from "../slices/Events";
import { Layout } from "../components/Layout";
import { GlobalBanner } from "../components/GlobalBanner";
import { NotFound } from "./NotFound";
import { Alert } from "@mui/material";

/**
 * Website homepage component
 */
export const RegisterDetailsPage = () => {
  const [home, eventsPageState] = useSinglePrismicDocument("events_page");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound =
    eventsPageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (eventsPageState.state === "failed") {
      console.warn(
        "Teampage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [eventsPageState.state]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js-eu1.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
        // @TS-ignore
        if (window.hbspt) {
            // @TS-ignore
            window.hbspt.forms.create({
              region: "eu1",
              portalId: "143151745",
              formId: "b6e8c7f6-c73e-4f06-abb8-5eb1fbc7c08c",
              target: '#registerForm'
            });
        }
    });
}, []);

  // Return the page if a document was retrieved from Prismic
  if (home && menu) {
    return (
      <Layout wrapperClass="page register-details-page" menuDoc={menu}>
        <GlobalBanner />
        <section className="content-section text-section-1col">
          <h1>Register your details</h1>
          <p>We are refreshing our contacts database. So that we can keep in-touch with you with regards to upcoming events, please do register your details</p>
          <div id="registerForm"></div>
          <br />
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
