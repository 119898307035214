import React, { useState } from "react";
import Moment from "moment";

import { PrismicRichText } from "@prismicio/react";
/**
 * Site event component
 */
export const Event = ({ ...event }) => {
  Moment.locale("en");

  const [expanded, setToggle] = useState(false);

  return (
    <section>
      <div className="col-4">
        <img src={event.image} />
      </div>
      <div className="col-8">
        <h3>{event.title}</h3>
        <h4>
          <strong>
            {event.startDate == event.endDate
              ? Moment(event.startDate).format("MMMM Do YYYY")
              : Moment(event.startDate).format("MMMM Do YYYY") +
                " - " +
                Moment(event.endDate).format("MMMM Do YYYY")}
          </strong>{" "}
          from <strong>{event.startFinishTime}</strong>
        </h4>
        <p className={"short-bio " + (expanded == true ? "hidden" : "")}>
          <PrismicRichText field={event.shortDescription} />{" "}
          <span onClick={() => setToggle(true)}>Read more...</span>
        </p>
        <p className={"full-bio " + (expanded == true ? "visible" : "hidden")}>
          <PrismicRichText field={event.longDescription} />{" "}
          <span onClick={() => setToggle(false)}>Read Less</span>
        </p>
      </div>
    </section>
  );
};
