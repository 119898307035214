import { useEffect, useState } from "react";
import {
  useSinglePrismicDocument,
  PrismicRichText,
  PrismicText,
} from "@prismicio/react";

import {
  Unstable_Grid2 as Grid,
  Divider,
  Chip
} from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import { Layout } from "../components/Layout";
import { GlobalBanner } from "../components/GlobalBanner";
import { OneOffPayment } from "../components/OneOffPayment";
import { BankTransferPayment } from "../components/BankTransferPayment";

import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const DonationsPage = () => {
  const [donations, donationsPageState] =
    useSinglePrismicDocument("donations_page");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound =
    donationsPageState.state === "failed" || menuState.state === "failed";

  const [showPaymentForm, setShowPaymentForm] = useState(false);

  useEffect(() => {
    if (donationsPageState.state === "failed") {
      console.warn(
        "Teampage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [donationsPageState.state]);

  // Return the page if a document was retrieved from Prismic
  if (donations && menu) {
    return (
      <Layout wrapperClass="page about-page" menuDoc={menu}>
        <GlobalBanner />
        <section className="content-section text-section-1col">
          <h1>Make a donation</h1>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Alert severity="info">
                <AlertTitle>It's Secure</AlertTitle>
                <section>We do not store your card number, or charge any additional fees to your account. Payment pages are loaded over HTTPS, which is a secure channel between you and our servers. <a target="_blank" href="https://www.getsafeonline.org/shopping-banking/online-payments/">Find out more...</a></section>
              </Alert>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Divider>
                <Chip label="Donate online now" />
              </Divider>
            </Grid>
            <br />
            <Grid xs={12}>
              <OneOffPayment />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Divider>
                <Chip label="Bank Transfer" />
              </Divider>
            </Grid>
            <br />
            <Grid xs={8}>
              <Alert icon={false} severity="warning">
                <AlertTitle>South London Suthars welcomes donations by bank transfer</AlertTitle>
                <section>
                  Please use your First name and Last name (FirstnameLastname) as the reference for your bank transfer or standing order. Alternatively if you would like to donate anonymously then please use the reference "OnlineDonation"
                </section>
                <br />
              </Alert>
            </Grid>
            <Grid xs={4}>
              <BankTransferPayment />
            </Grid>
          </Grid>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
