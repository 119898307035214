import React, { useState, useEffect } from "react";
import { AmountCaptureInputs } from "./AmountCaptureInputs";
import { BraintreeDropIn } from "./BraintreeDropIn";
import { useForm } from "react-hook-form";
import { 
  Button,
  Unstable_Grid2 as Grid,
  Alert,
  AlertTitle,
  Divider,
  Chip,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography
} from '@mui/material';

import { FormContainer, TextFieldElement } from 'react-hook-form-mui';


/**
 * Site OneOffPayment component
 */
export const OneOffPayment = () => {
  const [amount, setAmount] = useState(0);
  const [step, setStep] = useState("AMOUNT");
  const [personalData, setPersonalData] = useState(0);
  const [regularPayment, isRegularPayment] = useState(false);
 
  const onSubmitPersonalDetails = (data) => {
    setPersonalData(data);
    setStep("CAPTURE_PAYMENT");
  };

  const validateAmountAndSubmit = (amount) => {
    if (amount && amount >= 1) {
      setStep("CAPTURE_PERSON");
    } else {
      //Validation Error
    }
  };

  const setRegularSubscription = (event) => {
    isRegularPayment(event.target.checked);
  };


  const resetForm = () => {
    setAmount(0);
    isRegularPayment(false);
    setPersonalData(0);
    setStep("AMOUNT");
  };

  const paymentComplete = () => {
    setStep("PAYMENT_COMPLETE");
  };

  const paymentError = () => {
    setStep("PAYMENT_ERROR");
  };

  return (
    <section>
      <section
        className={
          "oneoff-donation-capture-amounts " +
          (step == "AMOUNT" ? "visible" : "hidden")
        }
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <AmountCaptureInputs onChange={setAmount} amount={amount} />
          </Grid>
          <Grid xs={6}>
            <h3>Make this a montly contribution?</h3>
            <FormControlLabel 
              control={<Switch checked={regularPayment} onChange={setRegularSubscription} />}
              label="Slide the toggle to opt to setup a monthly donation."
            />
          </Grid>
          <Grid xs={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                    We need your support! Regular donations allow us to manage how we run our events. A small monthly donation will really help us to bring our community events all year round.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={12}>
            <Button
              fullWidth
              disabled={amount < 1}
              size="large"
              variant="contained"
              onClick={() => validateAmountAndSubmit(amount)}
            >
              Continue
            </Button>
          </Grid>
        </Grid>  
      </section>
      <section
        className={
          "oneoff-capture-person " +
          (step == "CAPTURE_PERSON" ? "visible" : "hidden")
        }
      >

        <FormContainer
            onSuccess={data => onSubmitPersonalDetails(data)}
        >
          <Grid container spacing={2}>
            <Grid xs={12}>
            <Alert icon={false} severity="warning">
                <AlertTitle>We need some personal details</AlertTitle>
                <section>Our payment processor will use these details to ensure your payment is made securly by ensuring the details entered below match your card providers record. South London Suthars will use these details to keep track of donations made.</section>
              </Alert>
            </Grid>
            <Grid xs={6}>
              <TextFieldElement fullWidth name="firstName" label="First name" required/>
            </Grid>
            <Grid xs={6}>
            <TextFieldElement fullWidth name="lastName" label="Last name" required/>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <TextFieldElement fullWidth name="streetAddress" label="House Number/Name and Street name" required/>
            </Grid>
            <Grid xs={6}>
              <TextFieldElement fullWidth name="email" label="Email" type={'email'} required/>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Button type={'submit'} size="large" fullWidth variant="contained">Continue</Button>
            </Grid>
          </Grid>
        </FormContainer>
      </section>
      <section
        className={
          "oneoff-payment " + (step == "CAPTURE_PAYMENT" ? "visible" : "hidden")
        }
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
          <Alert icon={false} severity="warning">
              <AlertTitle>Card Details</AlertTitle>
              <section>You are about to donate <strong>£{amount}</strong> to the Shree Vishwakarma Navratri Commitiee.</section>
              { regularPayment && <section><br />We will also setup a montly payment of <strong>£{amount}</strong> which will be taken from this card on the 1st of each month. To cancel this regular donation, please email team@southlondonsuthars with your email address and full name.</section> }
            </Alert>
          </Grid>
          <Grid xs={12}>
            <BraintreeDropIn
              show={step == "CAPTURE_PAYMENT"}
              onPaymentCompleted={paymentComplete}
              onPaymentError={paymentError}
              customer={personalData}
              amount={amount}
              regularPayment={regularPayment}
            />
          </Grid>
        </Grid>
      </section>
      <section
        className={
          "payment-complete " +
          (step == "PAYMENT_COMPLETE" ? "visible" : "hidden")
        }
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Alert severity="success">
              <AlertTitle>Payment Success</AlertTitle>
              <div>
                <h3>
                  Thank you {personalData.firstName} for your donation of{" "} <strong>£{amount}</strong> to the Shree Vishwakarma Navratri Commitiee.
                </h3>
                {
                  regularPayment &&
                    <h3>Your regular payment has also been setup! Thank you for your support. To cancel this regular donation, please email team@southlondonsuthars with your email address and full name.</h3>
                }
              </div>
              <Button variant="contained" fullWidth size="large" color="primary" onClick={resetForm}>
                Done
              </Button>
            </Alert>
          </Grid>
        </Grid>
      </section>
      <section
        className={
          "payment-error " + (step == "PAYMENT_ERROR" ? "visible" : "hidden")
        }
      >
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Alert severity="error">
              <AlertTitle>Payment Error</AlertTitle>
              <div className="centered">
                <h3>
                  <strong>Oops... there has been an error trying to make your donation</strong>
                </h3>
                <br />
                <p>
                  <strong>We are currently experiencing problems processing larger value donations (~>£100). We are working to fix the issue., if you are trying to make a larger donation, please reach out to a member of the front desk team during the Navratri events, or you can use the bank transfer details below. We apologise for the inconvenience.</strong>
                </p>
                <p>
                  Please try again, or if the problem persists, then please contact us on team@southlondonsuthars.com
                </p>
              </div>
              <Button variant="contained" fullWidth size="large" color="primary" onClick={resetForm}>
                Try again
              </Button>
            </Alert>
          </Grid>
        </Grid>
      </section>
    </section>
  );
};
