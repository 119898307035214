import { useEffect } from "react";
import { SliceZone, useSinglePrismicDocument } from "@prismicio/react";

import { components } from "../slices";
import { Events } from "../slices/Events";
import { Layout } from "../components/Layout";
import { Event } from "../components/Event";
import { OneOffPayment } from "../components/OneOffPayment";
import { HomepageBanner } from "../components/HomepageBanner";
import { NotFound } from "./NotFound";
import { 
  Unstable_Grid2 as Grid,
  Alert,
  AlertTitle,
  Button
} from "@mui/material";

/**
 * Website homepage component
 */
export const HomePage = () => {
  const [home, homeState] = useSinglePrismicDocument("homepage");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [events, eventsPageState] = useSinglePrismicDocument("events_page");

  const notFound = homeState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (homeState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [homeState.state]);

  useEffect(() => {
    if (eventsPageState.state === "failed") {
      console.warn(
        "Events document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [eventsPageState.state]);

  // Return the page if a document was retrieved from Prismic
  if (home && menu && events) {
    const topEvent = events.data.body[0].items[0];
    return (
      <Layout wrapperClass="homepage" menuDoc={menu}>
        <HomepageBanner banner={home.data.homepage_banner[0]} />
        <section className="content-section text-section-1col">
          <h1>Register your details</h1>
          <p>We are upgrading our contacts database, so please do register your details so that we can keep you updated with all of our events!</p>
          <Button variant="contained" href="/register">
            Register
          </Button>
        </section>
        <section className="content-section text-section-1col">
          <h1>Upcoming Events</h1>
          <ul className="events-list">
            <Events slice={topEvent} id={1} />
          </ul>
          <h1>Make a donation</h1>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Alert severity="info">
                <AlertTitle>It's Secure</AlertTitle>
                <section>We do not store your card number, or charge any additional fees to your account. Payment pages are loaded over HTTPS, which is a secure channel between you and our servers. <a target="_blank" href="https://www.getsafeonline.org/shopping-banking/online-payments/">Find out more...</a></section>
              </Alert>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid xs={12}>
              <OneOffPayment />
            </Grid>
          </Grid>
        </section>

        <SliceZone slices={home.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
