import {
    Grid,
    CardContent,
    Typography,
    Card
} from "@mui/material";
/**
 * Bank Transfer Payment component
 */
 export const BankTransferPayment = () => (
    <section>
      <Grid container>
            <Grid xs={12}>
            <Card variant="outlined">
                <CardContent>
                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                        <strong>Bank Account Details</strong>
                    </Typography>
                    <Typography sx={{ mb: 1 }} color="text.secondary">
                        Sort Code: 20-24-64
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Account Number: 20704814
                    </Typography>
                </CardContent>
            </Card>
                <pre></pre>
                <pre></pre>
          </Grid>
      </Grid>
    </section>
  );
  