import { useEffect } from "react";
import {
  SliceZone,
  useSinglePrismicDocument,
  PrismicRichText,
  PrismicText,
} from "@prismicio/react";

import { Events } from "../slices/Events";
import { Layout } from "../components/Layout";
import { GlobalBanner } from "../components/GlobalBanner";
import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const EventsPage = () => {
  const [home, eventsPageState] = useSinglePrismicDocument("events_page");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound =
    eventsPageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (eventsPageState.state === "failed") {
      console.warn(
        "Teampage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [eventsPageState.state]);

  // Return the page if a document was retrieved from Prismic
  if (home && menu) {
    return (
      <Layout wrapperClass="page events-page" menuDoc={menu}>
        <GlobalBanner />
        <section className="content-section text-section-1col">
          <h1>
            <PrismicText field={home.data.title} />
          </h1>
          <article>
            <PrismicRichText field={home.data.eventsdescription} />
          </article>

          <ul className="events-list">
            {home.data.body[0].items.map((event, id) => (
              <Events slice={event} id={id} />
            ))}
          </ul>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
