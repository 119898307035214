/**
 * Homepage banner component
 */
export const GlobalBanner = () => (
  <section
    className="global-banner"
    style={{
      backgroundColor: "#231858",
    }}
  >
    <div className="banner-content container">
      <img src="/images/vishwakarma_dada.png" />
    </div>
  </section>
);
