import { useEffect } from "react";
import {
  useSinglePrismicDocument,
  PrismicRichText,
  PrismicText,
} from "@prismicio/react";

import { TeamMembers } from "../slices/TeamMembers";
import { CommitteeMembers } from "../slices/CommitteeMembers";
import { Layout } from "../components/Layout";
import { GlobalBanner } from "../components/GlobalBanner";
import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const AboutUsPage = () => {
  const [home, teamPageState] = useSinglePrismicDocument("team_page");
  const [menu, menuState] = useSinglePrismicDocument("menu");

  const notFound =
    teamPageState.state === "failed" || menuState.state === "failed";

  useEffect(() => {
    if (teamPageState.state === "failed") {
      console.warn(
        "Teampage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [teamPageState.state]);

  // Return the page if a document was retrieved from Prismic
  if (home && menu) {
    return (
      <Layout wrapperClass="page about-page" menuDoc={menu}>
        <GlobalBanner />
        <section className="content-section text-section-1col">
          <h1>
            <PrismicText field={home.data.pagetitle} />
          </h1>
          <article>
            <PrismicRichText field={home.data.aboutuspara} />
          </article>
          <br />
          <h1>
            <PrismicText field={home.data.subtitle} />
          </h1>
          <article>
            <PrismicRichText field={home.data.subtitleintro} />
          </article>
          {/* Team Members */}
          <ul className="meet-the-team-list">
            {home.data.body[0].items.map((person, id) => (
              <TeamMembers slice={person} id={id} />
            ))}
          </ul>
          <h1>Committee Members</h1>
          <ul className="meet-the-committee-list">
            {home.data.body[1].items.map((object, id) => (
              <CommitteeMembers slice={object} id={id} />
            ))}
          </ul>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
