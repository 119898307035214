import { render } from "react-dom";
import { App } from "./App";
import * as Sentry from "@sentry/react";

import "./styles/index.scss";
import "./styles/forms.scss";

Sentry.init({
    dsn: "https://5a4abfc16c859bc00ea990a6824c5eee@o4505711140995072.ingest.sentry.io/4505711142764544",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https:southlondonsuthars.com"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  

render(<App />, document.getElementById("root"));
