import React, { useEffect, useState } from "react";
// import './index.css';
import dropin from "braintree-web-drop-in";
import {Button} from "@mui/material";

export const BraintreeDropIn = (props) => {
  const { show, onPaymentCompleted, onPaymentError, regularPayment } = props;

  const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  const [deviceData, setDeviceData] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      const initializeBraintree = () =>
        dropin.create(
          {
            // insert your tokenization key or client token here
            authorization: process.env.REACT_APP_BRAINTREE_CLIENT_KEY,
            container: "#braintree-drop-in-div",
          },
          function (error, instance) {
            if (error) console.error(error);
            else setBraintreeInstance(instance);
          }
        );

      if (braintreeInstance) {
        braintreeInstance.teardown().then(() => {
          initializeBraintree();
        });
      } else {
        initializeBraintree();
      }
    }
  }, [show]);

  useEffect(() => {
    braintree.client.create(
      {
        authorization: process.env.REACT_APP_BRAINTREE_CLIENT_KEY,
      },
      function (err, clientInstance) {
        // Creation of any other components...
        braintree.dataCollector.create(
          {
            client: clientInstance,
          },
          function (err, dataCollectorInstance) {
            if (err) {
              // Handle error in creation of data collector
              return;
            }

            // At this point, you should access the dataCollectorInstance.deviceData value and provide it
            // to your server, e.g. by injecting it into your form as a hidden input.
            setDeviceData(dataCollectorInstance.deviceData);
          }
        );
      }
    );
  });

  return (
    <div style={{ display: `${show ? "block" : "none"}` }}>
      <div id={"braintree-drop-in-div"} />
      <div id="spinner" className={isLoading ? "show" : ""}>
        <div className="lds-dual-ring"></div>
      </div>
      <Button
        className={"braintreePayButton"}
        variant="contained"
        size="large"
        fullWidth
        type="primary"
        disabled={!braintreeInstance}
        onClick={() => {
          if (braintreeInstance) {
            braintreeInstance.requestPaymentMethod((error, payload) => {
              setLoading(true);
              if (error && error.name == "DropinError") {
                setLoading(false);
                return;
              }

              if (error) {
                setLoading(false);
                onPaymentError();
              } else {
                setLoading(true);
                const toSend = {
                  customer: props.customer,
                  deviceData: deviceData,
                  paymentMethodNonce: payload.nonce,
                  amount: props.amount,
                  regularPayment: regularPayment
                };
                const requestOptions = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(toSend),
                };
                fetch(
                  process.env.REACT_APP_API_URL +
                    "donation/one-off/transaction_regular",
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((data) => {
                    if (data.error == false) {
                      setLoading(false);
                      onPaymentCompleted();
                    } else {
                      setLoading(false);
                      onPaymentError();
                      //handle error state
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    onPaymentError();
                  });
              }
            });
          }
        }}
      >
        {"Make Donation"}
      </Button>
    </div>
  );
};
