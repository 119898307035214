import React, { useState } from "react";
import { PrismicRichText } from "@prismicio/react";

/**
 * Team members slice
 */
export const CommitteeMembers = ({ slice, id }) => {
  const [expanded, setToggle] = useState(false);
  return (
    <li key={id.toString()} className="">
        <h3 className="full-name">{slice.fullname[0].text}</h3>
        <img src={slice.facepicture.url} />
    </li>
  );
};
